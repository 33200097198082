<template>
  <div class="d-flex flex-row flex-wrap login">
    <div class="d-flex justify-center align-center login__form-wrapper">
      <div class="text-center welcome">
        <img src="@/assets/images/logo.svg" alt="login">
        <div class="welcome__title">Bienvenido</div>
        <div class="welcome__description mb-6"> Por favor ingresa con tus credenciales</div>

        <validation-observer ref="form">
          <form novalidate autocomplete="off" @submit.prevent="login">
            <validation-provider
              v-slot="{ errors }"
              name="Usuario"
              rules="required"
            >
              <v-text-field
                vid="username"
                v-model="model.username"
                type="username"
                outlined
                dense
                :error-messages="errors"
                label="Usuario"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              vid="password"
              v-slot="{ errors }"
              name="Contraseña"
              rules="required"
            >
              <v-text-field
                v-model="model.password"
                type="password"
                outlined
                dense
                :error-messages="errors"
                label="Contraseña"
                required
              ></v-text-field>
            </validation-provider>
            <v-btn block class="elevation-0 text-none" color="primary" type="submit">
              Ingresar
            </v-btn>
            <v-btn text small plain class="text-lowercase mt-5" @click="$router.push(`/password-reset/${fairSlug}`)">
              ¿<span class="text-uppercase">H</span>as olvidado tu contraseña?
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="login__banner">
    </div>
  </div>
</template>

<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import { LOGIN_URL, LOGOUT_URL, PUBLIC_FAIRS_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import notificationMixin from '@/mixins/notificationMixin'
import sessionMixin from '@/mixins/sessionMixin'
import routerMixin from '@/mixins/routerMixin'
import axios from 'axios'

export default {
  name: 'LoginExpositor',
  data: () => ({
    loginUrl: LOGIN_URL + '-admin-expositor',
    fairSlug: null,
    model: {
      username: '',
      password: '',
      fairId: null
    }
  }),
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isLoggedIn() && sessionMixin.methods.isCustomer(user.roleId)) {
      axios.post(LOGOUT_URL, { token: user.token })
        .then((res) => {
          sessionMixin.methods.clear()
          next()
        })
    } else if (sessionMixin.methods.isLoggedIn()) {
      const page = routerMixin.methods.goTo(sessionMixin.methods)
      next(page)
    } else {
      next()
    }
  },
  mixins: [
    crudServiceMixin,
    sessionMixin,
    loaderMixin,
    formOperationsMixin,
    notificationMixin,
    routerMixin
  ],
  methods: {
    getFairBySlug () {
      this.fairSlug = this.$route.params.slug
      this.getById(PUBLIC_FAIRS_URL, this.$route.params.slug).then((response) => {
        this.model.fairId = response.data.id
        this.hideLoader()
      })
    },
    login () {
      this.executeWithFormValidation(async () => {
        this.showLoader()
        this.post(this.loginUrl, this.model).then((response) => {
          const userInfo = response.data
          this.saveToLocalStorage(userInfo)
          const page = this.goTo(this)
          this.$router.go(page.path)
          this.hideLoader()
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$refs.form.errors.password.push('Credenciales incorrectas')
          } else if (error.response && error.response.status === 422) {
            this.showError(error.response.data.error)
          }
          this.hideLoader()
        })
      })
    }
  },
  created () {
    this.showLoader()
    this.getFairBySlug()
  }
}
</script>

<style lang="scss">
.login {
  height: 100vh;

  &__form-wrapper {
    width: 100%;
  }

  &__banner {
    background: url("../../../assets/images/login-expositor-banner.jpg") no-repeat center;
    background-size: cover;
    display: none;
    width: 55%;
  }
}

@media (min-width: 990px) {
  .login {
    &__form-wrapper {
      width: 45%;
    }

    &__banner {
      display: flex;
    }
  }
}

.welcome {
  width: 320px;

  img {
    width: 150px;
  }

  &__title {
    font-size: 30px;
    font-family: 'Roboto Bold', sans-serif !important;
  }

  &__description {
    color: #808080;
    line-height: 10px
  }

}

</style>
